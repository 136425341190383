<template>
  <div class="p-15">
    <div class="p-10 s_flex_bian_c s_bf m-b-15 tw-rounded-sm" @click="()=> {$router.push('/outWarehouse/byOrder')}">
      <img class="width-55 height-55 tw-rounded-sm" src="@/assets/image/adck.png" alt="">
      <div class="width80">
        <p class="fz-16">按单出库</p>
        <p class="tw-text-gray-400 m-t-5">出货人员按出库单进行定品、定量扫码出货，系统自动核对数据</p>
      </div>
    </div>
    <div class="p-10 s_flex_bian_c s_bf tw-rounded-sm" @click="()=> {$router.push('/outWarehouse/byFast')}">
      <img class="width-55 height-55 tw-rounded-sm" src="@/assets/image/ksck.png" alt="">
      <div class="width80">
        <p class="fz-16">快速出库</p>
        <p class="tw-text-gray-400 m-t-5">商品不需要先入库扫描，由出货人员自选商品、自定数量进行扫码出货，系统同步入库数据</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { inject
  } from "vue";
  export default {
    setup() {
      const $route = inject("$route");
      const $router = inject("$router");
    }
  };
</script>
